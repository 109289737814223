import React, { useState, useEffect } from "react";
import logo from "../images/logo/logo.png";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdGridView, MdCategory, MdInventory, MdOutlineShoppingCart, MdOutlineKeyboardDoubleArrowLeft, MdQrCode2 } from "react-icons/md";
import { PiFilePlusLight } from "react-icons/pi";
import { LuUserPlus } from "react-icons/lu";
import { AiOutlineUser } from "react-icons/ai";
import { FaList } from "react-icons/fa6";
import { CiLogout } from "react-icons/ci";
import { useAuth } from "./AuthContext";
import { Link, NavLink } from "react-router-dom";
import { FaPeopleArrows, FaShirtsinbulk } from "react-icons/fa";
import { GrUserAdmin } from "react-icons/gr";
import { RiAdvertisementLine } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { IoLocationSharp } from "react-icons/io5";

const SideBar = () => {
  const { logout } = useAuth();
  const [openMenu, setOpenMenu] = useState({});
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const isSuperAdmin =
    adminData && adminData.level?.name.toLowerCase() === "Super Admin";
  const isStaff = adminData && adminData.level?.name.toLowerCase() === "Aino Staff";
  
  
  const handleToggle = (menu) => {
    setOpenMenu((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  // State to manage sidebar toggle
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);

  // Toggle sidebar function
  const handleToggleSidebar = () => {
      setIsSidebarToggled(!isSidebarToggled);
      document.body.classList.toggle('button-show-hide');
  };


  const menuItems = [
    {
      path: "/dashboard",
      icon: <MdGridView />,
      label: "Dashboard",
    },
    {
      label: "Admin",
      icon: <GrUserAdmin />,
      subItems: [
        { path: "/dashboard/admin", label: "All Admin" },
        { path: "/dashboard/addadmin", label: "Add new Admin" },
      ],
    },
    {
      label: "User",
      icon: <LuUserPlus />,
      subItems: [{ path: "/dashboard/user", label: "All user" }],
    },
    {
      label: "Retailer",
      icon: <FaShirtsinbulk />,
      subItems: [{ path: "/dashboard/retailer", label: "All Retailer" }],
    },
    {
      label: "Referrals",
      icon: <FaPeopleArrows />,
      subItems: [{ path: "/dashboard/referrals", label: "All Referrals" }],
    },
    {
      label: "Merchant",
      icon: <LuUserPlus />,
      subItems: [{ path: "/dashboard/merchant", label: "All Merchant" }],
    },
    {
      label: "Aino Influencer",
      icon: <LuUserPlus />,
      subItems: [{ path: "/dashboard/influencer", label: "Aino Influencer" }],
    },
    {
      label: "Roles",
      icon: <AiOutlineUser />,
      subItems: [
        { path: "/dashboard/allroles", label: "All roles" },
        { path: "/dashboard/createroles", label: "Create role" },
      ],
    },
    {
      label: "Aino Banner",
      icon: <RiAdvertisementLine />,
      subItems: [
        { path: "/dashboard/advert", label: "Aino Banner" },
        { path: "/dashboard/addadvert", label: "Create New Banner" },
      ],
    },
    {
      label: "Categories",
      icon: <BiCategory />,
      subItems: [
        { path: "/dashboard/catergorieslist", label: "Categories" },
        { path: "/dashboard/newcatergories", label: "Create Categories" },
      ],
    },
    {
      label: "Sub Categories",
      icon: <MdCategory />,
      subItems: [
        { path: "/dashboard/subcategories", label: "Sub Categories" },
        { path: "/dashboard/NewSubCategories", label: "Create Sub Categories" },
      ],
    },
    {
      label: "Inventory",
      icon: <MdInventory />,
      subItems: [
        { path: "/dashboard/productlist", label: "Inventory" },
        { path: "/dashboard/addproduct", label: "Add Inventory" },
      ],
    },
    {
      label: "Order",
      icon: <PiFilePlusLight />,
      subItems: [{ path: "/dashboard/orderlist", label: "Order" }],
    },
    {
      label: "FlashSales",
      icon: <MdOutlineShoppingCart />,
      subItems: [
        { path: "/dashboard/flashsales", label: "Flash Sales" },
        { path: "/dashboard/addFlashSales", label: "Create Flash Sales" },
      ],
    },
    {
      label: "Transactions",
      icon: <PiFilePlusLight />,
      subItems: [
        { path: "/dashboard/transcationlist", label: "All Transactions" },
      ],
    },
    {
      label: "Pick up Location",
      icon: <IoLocationSharp />,
      subItems: [
        {
          path: "/dashboard/pickupstationlist",
          label: "Pick Up Location list",
        },
        { path: "/dashboard/newpickuplist", label: "Create New Location" },
      ],
    },
    {
      label: "Voucher Codes",
      icon: <MdQrCode2 />,
      subItems: [
        { path: "/dashboard/vouchercodelist", label: "Voucher Codes list" },
        { path: "/dashboard/newvouchercode", label: "Create New Voucher Code" },
      ],
    },
    { 
      path: "#", 
      label: "Logout", 
      icon: <CiLogout />, 
      onClick: logout 
    },
  ];




  return (
    <div className="section-menu-left">
      <div className="box-logo">
        <Link to="/dashboard">
          <img src={logo} alt="Logo" id="logo_header_mobile" style={{ height: "60px"}}/>
        </Link>
        <div className="button-show-hide" onClick={handleToggleSidebar}>
          <FaList />
        </div>
      </div>
      <div className="center">
        <div className="nav-style">
          <ul className="menu-list">
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.subItems ? (
                  <li className="menu-item has-children">
                    <Link to="#" onClick={(e) => { e.preventDefault(); handleToggle(item.label); }} className="menu-item-button">
                      <div className="icon">{item.icon}</div>
                      <div className="text">{item.label}</div>
                    </Link>
                    {openMenu[item.label] && (
                      <ul className="sub-menu">
                        {item.subItems.map((subItem, subIndex) => (
                          <li key={subIndex} className="sub-menu-item">
                            <NavLink to={subItem.path}>
                              <div className="text">{subItem.label}</div>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ) : (
                  <li className="menu-item not-dropdown">
                    <Link to={item.path || "#"} onClick={item.onClick}>
                      <div className="icon">{item.icon}</div>
                      <div className="text">{item.label}</div>
                    </Link>
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
